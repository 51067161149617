<template>
    <div class="content">
        <div class="m-card">
            <div class="form">
                <Form
                        :model="formInline"
                        ref="formInline"
                        :rules="ruleValidate"
                >
                    <FormItem :label-width="90">
                        <Input v-model="formInline.email" disabled 
                               placeholder="email"/>
                    </FormItem>
                    <FormItem :label-width="90" prop="newPassword">
                        <Input v-model="formInline.newPassword" password type="password" maxlength="20"
                               placeholder="newPassword"/>
                    </FormItem>
                    <FormItem  :label-width="90" prop="confirmPassword">
                        <Input v-model="formInline.confirmPassword" password type="password" maxlength="20"
                               placeholder="confirmPassword"/>


                    </FormItem>
                    <FormItem label="" :label-width="90">
                        <Button type="primary" style="width: 100px;height: 36px;" @click="handleSubmit('formInline')">
                            Submit
                        </Button>
                        <Button style="width: 100px;height: 36px;margin-left: 10px" @click="$router.go(-1)">Cancel</Button>
                    </FormItem>
                </Form>
            </div>

        </div>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "bind-edit",
        data() {
            const confirm = (rule, value, callback) => {
                if (this.formInline.newPassword) {
                    if (this.formInline.newPassword !== this.formInline.confirmPassword) {
                        callback(new Error('Passwords does not match!'));

                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            };
            const oldPassword = (rule, value, callback) => {
                if (!this.formInline.oldPassword) {
                    callback(new Error('Please enter oldPassword'));
                } else {
                    callback();
                }
            };
            const newPassword = (rule, value, callback) => {
                if (!this.formInline.newPassword) {
                    callback(new Error('Please enter newPassword'));
                }
                if (this.formInline.newPassword.length < 6) {
                    callback(new Error('Password no less than 6'));
                } else {
                    callback();
                }
            };
            return {
                formInline: {
                    email: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                ruleValidate: {
                    oldPassword: [
                        {validator: oldPassword, trigger: "blur"},
                    ],
                    newPassword: [
                        {validator: newPassword, trigger: "blur"},
                    ],
                    confirmPassword: [
                        {validator: confirm, trigger: "blur"}
                    ]
                },
                hasoldPassword: true

            }
        },
        computed: {
            ...mapGetters({
                userId: "getUserId",
                user: "getUser",
            }),
        },
        created() {
            console.log(this.user,'--')
            this.formInline.email = this.user.email;
            this.getUserPassword()
        },
        methods: {
            ...mapActions({
                getPassword: "user/getPassword",
                editNewPassword: "user/editNewPassword",
                editPassword:"user/editPassword",
                changPSW:'en/changPSW'
            }),
            async getUserPassword() {
                let data = await this.getPassword(this.userId)
                this.hasoldPassword = data.data
                console.log(data, 'data')
            },
            async handleSubmit(name) {
                let valid = await this.validForm(name);
                if (!valid) return;
                console.log()
                let body = {
                    email:this.formInline.email,
                    password:this.formInline.newPassword,
                };
                let res = await this.changPSW(body);
                if(res.result){
                    this.$Message.success('success')
                    this.$router.go(-1);//返回上一层
                } else {
                    his.$Message.warning('fail')
                }
            },

            validForm(name) {
                // 验证表单
                console.log(name, 'name')
                return new Promise((resolve, reject) => {
                    this.$refs[name].validate((valid) => {
                        resolve(valid);
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
    .content {
        min-height: 700px;

        .m-card {
            border: solid 1px #ededed;

            .mc-top-title::before {
                opacity: 0;
                font-size: 16px;
            }
        }

        .form {
            width: 425px;
            padding: 40px;
        }

        ::v-deep {
            .ivu-form-item {
                margin-bottom: 30px;
            }

            .ivu-form {
                .ivu-form-item-label {
                    color: #666;
                }
            }

        }
    }
</style>
